<script setup lang="ts">
const props = defineProps<{
  title: string
  value?: number | string
  icon: string
  link?: string
  chip?: number | string
  alertText?: string
}>()

const slots = useSlots()
</script>

<template>
  <UCard
    class="relative overflow-hidden rounded-lg"
    :class="{
      'pb-6': props.link,
    }"
  >
    <dt>
      <div class="absolute">
        <UTooltip v-if="alertText" :text="alertText">
          <UChip color="amber">
            <div class="rounded-md bg-sky-500 p-3 leading-[0]">
              <UIcon :name="props.icon" class="block size-6 text-white" />
            </div>
          </UChip>
        </UTooltip>

        <div v-else class="rounded-md bg-sky-500 p-3 leading-[0]">
          <UIcon :name="props.icon" class="size-6 text-white" />
        </div>
      </div>

      <p class="ml-16 truncate text-sm font-medium text-gray-500">
        {{ props.title }}
      </p>
    </dt>

    <dd
      class="ml-16 flex items-center"
      :class="{
        'pb-6': props.link,
      }"
    >
      <p
        class="min-h-8 text-2xl font-semibold text-gray-900 first-letter:uppercase"
      >
        <span v-if="!slots.value"> {{ props.value }} </span>

        <slot name="value" />
      </p>
      <UBadge
        v-if="chip"
        class="ml-2"
        :label="Number(chip) > 0 ? `+${chip}` : chip"
        :color="Number(chip) < 0 ? 'red' : 'green'"
        variant="soft"
      />
      <div
        v-if="props.link"
        class="absolute inset-x-0 bottom-0 bg-gray-50 px-6 py-4"
      >
        <div class="text-sm">
          <NuxtLink
            :to="props.link"
            class="font-medium text-sky-600 hover:text-sky-500"
          >
            Ver mais
            <span class="sr-only"> {{ props.title }} </span>
          </NuxtLink>
        </div>
      </div>
    </dd>
  </UCard>
</template>
